import axios from "axios";
import Utils from "./utils/utils";
import { notification } from "antd";

const instance = axios.create({
  baseURL: `${Utils.ApiEndpoint}`,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && (401 === error.response.status || 403 === error.response.status)) {
      console.log("Got 401 response.");
      localStorage.clear();
      console.log("Local storage cleared");
      window.location.replace("/login");
    } else {
      return Promise.reject(error);
    }
  }
);
/**
 *
 * @param {*} method
 * @param  {...any} data
 * @returns {Promise<AxiosResponse<any, any>>}
 */
const runAPI = (method, ...data) =>
  instance[method](...data).catch((e) => {
    let message = e.response && e.response.data ? e.response.data.detail : e.message
    return { error: message };
  });

export default instance;
function auth(pa) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const tk = auth_token_type + " " + auth_token;
  return { headers: { Authorization: tk }, params: pa };
}
function authForm(pa) {
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const tk = auth_token_type + " " + auth_token;
  return {
    headers: { Authorization: tk, "Content-Type": "multipart/form-data" },
  };
}
function Src(url) {
  return (
    Utils.ApiEndpoint +
    "/variable/image?path=" +
    url +
    "&token=" +
    localStorage.getItem("auth_token")
  );
}
const


  API = {
    tags: {
      getAll: () => instance.get("/tags/get-all", auth()),
      create: ({ data }) => instance.post("/tags/create", data, auth()),
      update: ({ id, data }) => instance.put("/tags/update/" + id, data, auth()),
      remove: ({ id }) => instance.delete("/tags/remove", auth({ id })),
    },
    section: {
      update: ({ data }) =>
        instance.post("/sections/update-section", data, auth()),
      variable: ({ section_id }) =>
        instance.get("/sections/get-variable-section", auth({ section_id })),
      getForCreate: ({ brand_id, industry_id }) =>
        instance.get(
          "/sections/get-section-create",
          auth({ brand_id, industry_id })
        ),
    },
    variable: {
      variable: ({ section_id }) =>
        instance.get("/sections/get-variable-section", auth({ section_id })),
      getContent: ({ section_id }) =>
        instance.get("/sections/get-variable-content", auth({ section_id })),
      getByTag: ({ tagId }) =>
        instance.get("/variable/get-variable-by-tag", auth({ tag_id: JSON.stringify(tagId) })),
      updateVariableContent: ({ variable }) =>
        instance.post("/sections/update-variable", { variable }, auth()),
      getDefinition: ({ brand_id }) =>
        instance.get("/brands/get-definition-brand", auth({ BrandId: brand_id })),
    },
    document: {
      getForCreate: ({ brand_id, industry_id }) =>
        instance.get(
          "/documents/get-section-create",
          auth({ brand_id, industry_id })
        ),
    },
    brand: {
      get: ({ id }) => instance.get("/brands/get-brand", auth({ brand_id: id })),
      getBrand: ({ id }) => instance.get("/documents/get-brand", auth({ brand_id: id })),
      getAll: () => instance.get("/brands/get-brands", auth()),
      create: ({ data }) => instance.post("/brands/create-brand", data, auth()),
      update: ({ data }) => instance.post("/brands/update-brand", data, auth()),
      variable: ({ brand_id }) =>
        instance.get("/brands/get-variable", auth({ BrandId: brand_id })),
    },
    uploadImage: ({ FormData }) =>
      instance.post("/documents/upload-image", FormData, authForm()),
    role: {
      get: ({ id }) => instance.get("/roles/get/" + id, auth()),
      getAll: () => instance.get("/roles/get-all", auth()),
      create: ({ data }) => runAPI("post", "/roles/create", data, auth()),
      update: ({ id, data }) =>
        runAPI("put", "/roles/update/" + id, data, auth()),
      remove: ({ id }) => runAPI("delete", "/roles/remove/" + id, auth()),
      getPer: () => instance.get('/roles/get-permission', auth())
    },
    permission: {
      getAll: () => instance.get("/permissions/get-all", auth()),
      create: ({ data }) => runAPI("post", "/permissions/create", data, auth()),
      update: ({ id, data }) =>
        runAPI("put", "/permissions/update/" + id, data, auth()),
      remove: ({ id }) => runAPI("delete", "/permissions/remove/" + id, auth()),
    },
    menu: {
      getAll: () => instance.get("/menus/get-all", auth()),
      getMenu: () => instance.get("/menus/get-menu", auth()),
      getMyProfile: ({ id }) => instance.get("/menus/getprofile", auth({ id: id }))
    },
    systemInfo: {
      create: (data) => instance.post("/system_info/update-system", { item: data }, auth()),
      get: () => instance.get("/system_info/get", auth())
    }
  };
const keyEditor =
  "f94233f8035952b8ed1d02bd76712e35f8a0abf2e0902edc291d60793187b4fa";
// const keyEditor = 'wvjg6527soe5a11ml4srj57micpfnbvce7gm1u00bd4t8t10'
export { auth, API, Src, keyEditor };
