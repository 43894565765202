import { Flex, Form, Input, Modal, Select, Tag, Transfer } from "antd";
import { useEffect, useState } from "react";
import { API } from "../configs";
import axios from '../configs'

export default function UpdateRoleUser({ modal: [item, setModal], formAction, form }) {
    const [allRole, setAllRole] = useState([])
    const [roleUser, setRoleUser] = useState([])
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    useEffect(() => {
        API.role.getAll().then(({ data }) => {
            if (data) {
                setAllRole(data.result)
            }
        })
       
    }, [])
   
    return <Modal {...item} onCancel={() => setModal({})} forceRender onOk={() => form.submit()} okButtonProps={{ loading: item.status, icon: item.icon }}>
        <Form autoComplete="off" form={form} onFinish={formAction} labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="name" label="User" readOnly >
                <Input />
            </Form.Item>
            <Form.Item name="roleid" label="Role" required rules={[{ required: true, message: 'Role is required' }]}>
                <Select mode="multiple" showSearch filterOption={(input, option) => (option?.search ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={allRole.map(x=>({label: <Flex justify="space-between">{x.role_name} </Flex>, search: x.role_name, value: x.id}))} />
            </Form.Item>
        </Form>
    </Modal>
}