import React from 'react';
// import Button from '@mui/material/Button';
import axios from '../configs'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useState } from 'react'
import { SearchOutlined} from '@ant-design/icons';
import Utils from "../utils/utils";
import { Table, Modal,Tag, Space, Input, Pagination } from 'antd';
const { Column } = Table;


const Authorized_Access = () => {

  const navigate = useNavigate();
 
  const [loading, setLoading] = useState(false)
  const [accessrData, setAccessData] = React.useState([]);
  const [curentPage, setcurentPage] = useState(1)
  const [search, setSearch] = useState()
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)

  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);

  let user = Utils.parseJwt(auth_token);
  // get info user login



  function ButtonCancle() {
    return navigate("/home");
  };

  const fetchData = async () => {
    let options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    try {
      await axios.get(`/user-managements/access-log`, {
        headers: { Authorization: token },
      })
        .then((response) => {
          // console.log("access-log", response.data.result);
          // const ListUserData = response.data.result;

          let listUser = response.data.result.map(item => ({
            key: item.id,
            username: item.user_name,
            email: item.email,
            reason: item.reason,
            name: item.name,
            status: item.status,
            role: item.role,
            list_role_for_user: item.list_role_for_user,
            modified:new Date(item.modified_at).toLocaleDateString('vi-VN', options),
            created:new Date(item.created_at).toLocaleDateString('vi-VN', options)
          }));
          // console.log("listUser", listUser);
          //setInfoLoginUser({ ...response.data.result });
          setAccessData(listUser);
          
          toast.success(response.data.result);


        })
    } catch (e) {
      console.error(e);
    }
  }
  //let total = 0;

  const fetchData2 = async (page, limit)=>{
    setLoading(true);
   
    var _limit = limit ?? 10
    var _skip= (page -1)* _limit
    console.log("fetchData2 search",search);
    var _filter= search 
    axios.get(`/user-managements/access-log2`, {
      headers: { Authorization: token },
      params:{
        _skip: _skip,
        _limit:_limit,
        _filter:_filter
      }
    })
    .then((response) => {
      console.log("access-log", response.data.result)
      let DataUser = response.data.result
      let listUser = DataUser.data.map(item => ({
        key: item.id,
        username: item.user_name,
        email: item.email,
        reason: item.reason,
        name: item.name,
        status: item.status,
        role: item.role,
        list_role_for_user: item.list_role_for_user,
        
        modified: new Date(item.modified_at).toLocaleDateString() +' '+ new Date(item.modified_at).toLocaleTimeString(),
        created:new Date(item.created_at).toLocaleDateString()+ ' ' + new Date(item.created_at).toLocaleTimeString()
      }));
      setAccessData(listUser);
      setTotal(DataUser.total);
      console.log("total", total);
      console.log("DataUser.total", DataUser.total);
      setLoading(false)

    })
  }
  React.useEffect(() => {
    //fetchData();
    setLoading(true);
    setcurentPage(1);
    fetchData2(1, limit);
  }, [])
  React.useEffect(()=>{
    setLoading(true);
    const delay = setTimeout(()=>{
      console.log("search", search);
      //reset paging =1
      setcurentPage(1);
      fetchData2(1, limit);
    },500);
    return ()=> clearTimeout(delay);

  },[search, setSearch]);

  const onChange = (pageNumber) => {
    setcurentPage(pageNumber)
    fetchData2(pageNumber,limit);
  };


  return (
    <>
      <div >
        <div className='csstext' style={{ fontSize: '18px', fontWeight: 'bold', marginLeft: '15px', padding: '10px' }}>Access Log</div>
        <Space wrap style={{ padding: '0 1rem' }}>
          <Input onInput={(e)=>setSearch(e.target.value)} style={{ width: 250 }} prefix={<SearchOutlined />} placeholder='Search by email' />
        </Space>


        {/* <Table  pagination={false} dataSource={accessrData.filter(x => !search || ([ x.email, x.list_role_for_user.map(i=>i.role_name).join(' ')].join(' ')).includes(search))} > */}
        <Table loading={loading} pagination={false} dataSource={accessrData} >
          <Column title="Action" dataIndex="name" key="name" />
          <Column title="Reason" dataIndex="reason" key="reason" />
          {/* <Column title="User " dataIndex="name" key="name" /> */}
          <Column title="Email" dataIndex="email" key="email" />
          <Column title="Status" dataIndex= "status" render={ e => e == 0 ? <Tag color="success">Active</Tag>: <Tag color="warning">Deactivated</Tag>}  key="status" />
              
          <Column title="Role" dataIndex="list_role_for_user"
              render={e=>e?.map(i=><Tag key={i.roleid}>{i.role_name}</Tag>)}
              key="list_role_for_user" />
          <Column title="Modified" dataIndex="modified" key="modified" />
          <Column title="Created" dataIndex="created" key="created" />
         
        </Table>
        <Pagination defaultCurrent={curentPage} current={curentPage} total={total} onChange={onChange} style={{float:'right', paddingTop:'15px'}}/>

      </div>

    </>
  )

}
export default Authorized_Access
