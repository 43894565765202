import {
  Button,
  Collapse,
  Flex,
  Form,
  Input,
  Popconfirm,
  Radio,
  Select,
  Skeleton,
  Space,
  Tag,
  notification,
} from "antd";
import { useCallback, useContext, useEffect, useRef } from "react";
import Border from "../../components/Border";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import ShowOutline from "../../components/ShowOutline";
import { Guid } from "js-guid";
import { Editor } from "@tinymce/tinymce-react";
import axios, { API, auth } from "../../configs";
import { Context } from "../../App";
import { configTiny } from "../TemplateTabs";
import ModalConfig from "./ModalConfig";

/** @param {Document} doc */
export const updatePosition = (doc) => {
  doc?.querySelectorAll("li>p").forEach((e) => {
    let li = /**@type {HTMLElement}*/ e.closest("li");

    li.style.cssText = e.style.cssText;
    li.innerHTML = e.innerHTML;
  });

  doc?.querySelectorAll(".callout, img").forEach((e) => {
    let p = e.closest("p");
    if (p) {
      let p_rect = p.getBoundingClientRect();
      let img_rect = e.getBoundingClientRect();
      let x = parseInt(img_rect.x - p_rect.x);
      let y = parseInt(img_rect.y - p_rect.y);
      e.setAttribute("data-position", x + " " + y);
    }
    let li = e.closest("li");
    if (li) {
      let li_rect = li.getBoundingClientRect();
      let body = e.closest("body").getBoundingClientRect();
      let img_rect = e.getBoundingClientRect();
      let x = parseInt(img_rect.x - body.x);
      let y = parseInt(img_rect.y - li_rect.y);
      e.setAttribute("data-position", x + " " + y + " li");
    }
  });

  doc?.querySelectorAll("table").forEach((/** @type {HTMLElement} */ e) => {
    let width = e.getBoundingClientRect().width;
    let group = e.querySelector("colgroup");
    if (!group) {
      let colgroup = doc.createElement("colgroup");

      e.querySelector("tr").childNodes.forEach(
        (/** @type {HTMLElement} */ i) => {
          let col = doc.createElement("col");
          col.style.width =
            ((i.getBoundingClientRect().width / width) * 100).toFixed(2) + "%";
          col.setAttribute("data-mce-style", col.style.cssText);
          colgroup.append(col);
        }
      );

      e.appendChild(colgroup);
    } else {
      let cell = Array.from(e.querySelector("tr")?.childNodes ?? []);
      group.querySelector("col").childNodes.forEach((col, i) => {
        col.style.width =
          ((cell[i].getBoundingClientRect().width / width) * 100).toFixed(2) +
          "%";
        col.setAttribute("data-mce-style", col.style.cssText);
      });
    }
    e.style.width = width + "px";
    e.setAttribute("data-mce-style", e.style.cssText);

    let colgroup = e.querySelector("colgroup");
    let tbody = e.querySelector("tbody");
    let thead = e.querySelector("thead");
    e.innerHTML =
      (colgroup ? colgroup?.outerHTML : "") +
      (thead ? thead?.outerHTML : "") +
      (tbody ? tbody?.outerHTML : "");
  });

  doc?.querySelectorAll("td").forEach((/** @type {HTMLElement} */ e) => {
    e.style.width = e.getBoundingClientRect().width + "px";
    e.style.removeProperty("height");
    e.setAttribute("data-mce-style", e.style.cssText);
  });

  doc?.querySelectorAll("tr").forEach((/** @type {HTMLElement} */ e) => {
    e.style.height = e.getBoundingClientRect().height + "px";
    e.setAttribute("data-mce-style", e.style.cssText);
  });
};

export default function Template({ tab }) {
  const context = useContext(Context);
  const [variable, setVariable] = context.variable;
  const [varSrc, setVarSrc] = context.varSrc;
  const [isWhen, setIsWhen] = context.isWhen;
  const [saved, setsaved] = context.saved;
  const [openShowOutline, setOpenShowOutline] = useState(false);
  const [item, setItem] = context.item;
  const [tag, setTag] = useState();
  const [loading, setloading] = useState(true);
  const [loadingE, setLoadingE] = useState(true);
  const [update, setUpdate] = useState(false);
  const editorRef = useRef(null);
  const fontSize = configTiny.font_size_formats.split(" ");
  const font_family_formats = configTiny.font_family_formats.split(";");
  const { Panel } = Collapse;

  const SaveSection = () => {
    setloading(true);

    var lst = Array.from(
      editorRef.current.getDoc()?.querySelectorAll('img[src^="{{"][src$="}}"]')
    ).map((/**@type {HTMLImageElement}*/ x) =>
      x.getAttribute("data-mce-src").replace(/^{{/, "").replace(/}}$/, "")
    );

    editorRef.current
      .getDoc()
      ?.querySelectorAll("span.callout")
      .forEach((e) => {
        let div = document.createElement("div");
        div.innerHTML = e.getAttribute("data-html-src");
        lst = lst.concat(
          Array.from(div?.querySelectorAll('img[src^="{{"][src$="}}"]')).map(
            (/**@type {HTMLImageElement}*/ x) =>
              x.getAttribute("src").replace(/^{{/, "").replace(/}}$/, "")
          )
        );
      });

    updatePosition(editorRef.current.getDoc());

    var ItemData = {
      section_id: Guid.newGuid().toString(),
      industry_id: item.tags,
      name: item.section,
      title: item.sectionTitle,
      detail: editorRef.current.getContent(),
      title_config: JSON.stringify(item.titleConfig),
      variable: varSrc
        .map((x) => ({
          variable_id: Guid.newGuid().toString(),
          name: x,
          type: "text",
        }))
        .concat(
          ...lst.map((i) => ({
            variable_id: Guid.newGuid().toString(),
            name: i,
            type: "image",
          }))
        ),
      variableData:
        isWhen === false
          ? []
          : variable.map((x) => ({
              variable_id: x.key,
              name: x.name,
              operator: x.operator,
              value: x.value ?? "",
              definition: x.definition ?? "",
            })),
    };

    axios
      .post("/sections/create-section", ItemData, auth())
      .then(() => {
        notification.success({ message: "Success" });
        localStorage.removeItem("item");
        resetData();
      })
      .finally(() => setloading(false));
  };

  function add() {
    setVariable([
      ...variable,
      { key: Guid.newGuid().toString(), operator: "=" },
    ]);
  }

  function remove({ key }) {
    setVariable((v) => v.filter((x) => x.key !== key));
  }
  function checkSaveBtn() {
    return (
      (item.tags?.length &&
      item.section &&
      loading === false &&
      item.sectionTitle &&
      (isWhen === false ||
        variable.filter((x) => x.name && x.value).length === variable.length)
        ? false
        : true) || !(/^\d+(\.\d+)*$/g.test(item.section) || !item.section)
    );
  }
  useEffect(() => {
    if (tab === "new") {
      API.tags
        .getAll()
        .then((response) => {
          setTag(
            response.data.result.map((x) => ({
              label: x.name,
              value: x.industry_id,
            }))
          );
        })
        .catch(() => {
          setTag([]);
          return null;
        })
        .finally(() => setloading(false));
    }
  }, [tab]);
  const resetData = useCallback(() => {
    editorRef.current?.setContent("");
    setIsWhen(false);
    setVarSrc([]);
    setVariable([]);
    setItem({
      tags: [],
      section: "",
      sectionTitle: "",
      content: "",
      titleConfig: {
        font: "Tahoma",
        fontSize: "12pt",
      },
    });
    setsaved(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsWhen, setItem, setsaved]);

  useEffect(() => {
    if (tab === "1") resetData();
  }, [resetData, tab]);

  const handleBlur = useCallback(() => {
    let var_render = [];

    /**@type {Document}*/ let doc = editorRef.current.getDoc();
    let src = document.createElement("div");
    src.innerHTML = Array.from(doc?.querySelectorAll("span.callout")).reduce(
      (str, e) => (str += e.getAttribute("data-html-src")),
      ""
    );

    let title = item.sectionTitle;
    let varText = Array.from(doc?.querySelectorAll("i"))
      .map((x) => x.innerText)
      .concat([...title.matchAll(/{{(.*?)}}/g)].map((x) => x[1]))
      .concat(Array.from(src?.querySelectorAll("i")).map((x) => x.innerText));

    var_render = [...new Set(varText)];

    setVarSrc(var_render);
    setVariable((variable) =>
      variable.filter((x) => var_render.includes(x.name))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.sectionTitle]);

  useEffect(() => {
    if (
      !(
        varSrc.length === 0 &&
        variable.length === 0 &&
        item.section === "" &&
        item.sectionTitle === "" &&
        item.tags.length === 0
      )
    ) {
      setsaved("draft");
    } else {
      setsaved(true);
    }
  }, [item, setsaved, varSrc.length, variable.length]);

  useEffect(() => {
    if (editorRef.current) {
      if (item.section !== "") {
        let count = item.section.split(".").length;
        if (count > 6) count = 6;
        editorRef.current.dom.doc?.body.style.setProperty("--indent", count);
      } else editorRef.current.dom.doc?.body.style.setProperty("--indent", 0);
    }
  }, [item.section]);
  useEffect(() => {
    if (update === true) {
      setUpdate(() => {
        handleBlur();
        return false;
      });
    }
  }, [handleBlur, update]);
  return loading ? null : (
    <>
      <div style={{ margin: "0 auto", maxWidth: 1024 }}>
        <ModalConfig />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          <h3>Create a new template</h3>
          {saved === "draft" ? (
            <Space size="small">
              <Tag color="warning">Draft</Tag>
              <Popconfirm
                title="You really want to reset?"
                onConfirm={resetData}
              >
                <Button size="small" type="default">
                  Reset
                </Button>
              </Popconfirm>
            </Space>
          ) : null}
          <Button
            style={{ marginLeft: "auto" }}
            disabled={checkSaveBtn()}
            type="primary"
            onClick={SaveSection}
          >
            Save Section
          </Button>
        </div>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header={<b>New</b>} key="1">
            <Form>
              <div style={{ display: "flex", gap: 16 }}>
                <Form.Item
                  style={{ flex: 1 }}
                  label="Tags"
                  labelCol={{ style: { width: 70 } }}
                  required
                >
                  <Select
                    allowClear
                    mode="multiple"
                    onChange={(e) => {
                      item.tags = e;
                      setItem({ ...item });
                    }}
                    size="large"
                    value={item.tags}
                    options={tag}
                    placeholder="Select tag"
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  />
                </Form.Item>
                <Button
                  size="large"
                  disabled={!item.tags?.length}
                  onClick={() => setOpenShowOutline(true)}
                >
                  Show Outline
                </Button>
              </div>

              <div style={{ display: "flex", gap: 8 }}>
                <Form.Item label="Section" required>
                  <Input
                    status={
                      /^\d+(\.\d+)*$/g.test(item.section) || !item.section
                        ? "success"
                        : "error"
                    }
                    onChange={(e) => {
                      item.section = e.target.value;
                      setItem({ ...item });
                    }}
                    size="large"
                    value={item.section}
                    style={{ width: 100 }}
                  />
                </Form.Item>
                <Form.Item style={{ flex: 1 }} label="Section Title" required>
                  <Flex vertical gap={8}>
                    <Input
                      onChange={(e) => {
                        item.sectionTitle = e.target.value;
                        setItem({ ...item });
                      }}
                      size="large"
                      onBlur={(e) => {
                        item.sectionTitle = e.target.value.replace(
                          /\{\{([\w\s-|&nbsp;]+)\}\}/g,
                          (m, i) =>
                            "{{" + i.trim().split(/\s+/).join("_") + "}}"
                        );
                        setItem({ ...item });
                        setUpdate(true);
                      }}
                      value={item.sectionTitle}
                      placeholder="Enter section title"
                    />
                    <Flex gap={8}>
                      <Select
                        value={item.titleConfig.font}
                        onChange={(_, change) => {
                          item.titleConfig.font = change.value;
                          setItem({ ...item });
                        }}
                        style={{ flex: 3 }}
                        placeholder="Select font"
                        options={font_family_formats.map((e) => {
                          let [title, value] = e.split("=");
                          return {
                            label: (
                              <span style={{ fontFamily: value }}>{title}</span>
                            ),
                            value,
                          };
                        })}
                      />
                      <Select
                        value={item.titleConfig.fontSize}
                        onChange={(_, change) => {
                          item.titleConfig.fontSize = change.value;
                          setItem({ ...item });
                        }}
                        s
                        style={{ flex: 2 }}
                        placeholder="Select font size"
                        options={fontSize.map((e) => {
                          return {
                            label: e,
                            value: e,
                          };
                        })}
                      />
                    </Flex>
                  </Flex>
                </Form.Item>
              </div>
              <Flex gap={8} wrap="wrap">
                <span>Section used when: </span>
                <Space
                  style={{ marginBottom: 16, flex: 1 }}
                  direction="vertical"
                >
                  <Radio.Group
                    value={!isWhen ? "all" : "when"}
                    direction="vertical"
                    onChange={(f) => {
                      setIsWhen(f.target.value === "all" ? false : true);
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value="all">All the time</Radio>
                      <Radio value="when">When this variable</Radio>
                    </Space>
                  </Radio.Group>
                  <Space
                    direction="vertical"
                    style={{
                      overflowY: "auto",
                      maxHeight: "170px",
                      width: "100%",
                      alignItems: "end",
                    }}
                  >
                    {isWhen ? (
                      <>
                        {variable.map((x, i) => (
                          <Space.Compact
                            key={x.key}
                            size="middle"
                            style={{ alignItems: "baseline" }}
                          >
                            <Select
                              showSearch
                              value={x.name}
                              onChange={(e) => {
                                variable[i].name = e;
                                setVariable([...variable]);
                              }}
                              placeholder="Variable"
                              options={varSrc.map((i) => ({
                                value: i,
                                label: (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: i,
                                    }}
                                  />
                                ),
                              }))}
                              style={{ width: 150 }}
                            />
                            <Select
                              onChange={(e) => {
                                variable[i].operator = e;
                                setVariable([...variable]);
                              }}
                              value={x.operator}
                              style={{ width: 70 }}
                              options={[
                                { label: "=", value: "=" },
                                { label: "<>", value: "<>" },
                              ]}
                            />
                            <Input
                              value={x.value}
                              onInput={(e) => {
                                variable[i].value = e.target.value;
                                setVariable([...variable]);
                              }}
                              style={{ flex: 1 }}
                              placeholder="Value"
                            />
                            <Button
                              title="Remove"
                              onClick={() => remove(x)}
                              icon={<MinusOutlined />}
                            />
                          </Space.Compact>
                        ))}
                      </>
                    ) : null}
                  </Space>
                  {isWhen ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        gap: 8,
                        justifyContent: "end",
                      }}
                    >
                      {variable.length > 1 ? (
                        <Popconfirm
                          placement="left"
                          title="Clear all Variable"
                          onConfirm={() => setVariable([])}
                          okText="Clear"
                          okButtonProps={{ danger: true }}
                          cancelText="Cancel"
                        >
                          <Button size="middle">Clear All</Button>
                        </Popconfirm>
                      ) : null}

                      {varSrc.length === 0 ? (
                        <div style={{ textAlign: "center", width: "100%" }}>
                          No variables found in the content
                        </div>
                      ) : (
                        <Button
                          style={{ marginBottom: 16 }}
                          size="middle"
                          icon={<PlusOutlined />}
                          onClick={add}
                        >
                          New variable
                        </Button>
                      )}
                    </div>
                  ) : null}
                </Space>
              </Flex>
            </Form>
          </Panel>
        </Collapse>

        <Border title="Editor" style={{ padding: 4, marginTop: 16 }}>
          {loadingE && (
            <Skeleton style={{ padding: 8 }} loading active paragraph />
          )}
          <Editor
            tinymceScriptSrc="/static/tinymce/tinymce.js"
            onInit={(_, editor) => {
              editorRef.current = editor;
              setLoadingE(false);
            }}
            onBlur={() => setUpdate(true)}
            initialValue=""
            tabIndex={0}
            init={configTiny}
          />
        </Border>
      </div>
      <ShowOutline
        open={openShowOutline}
        setOpen={setOpenShowOutline}
        industryId={item.tags}
        tag={tag}
      />
    </>
  );
}
