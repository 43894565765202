import { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { Flex, Form, Input, Modal, Select } from "antd";

import { API } from "../configs";
import './register.css';


export default function AddUser({ modal: [item, setModalAdd], formAction, form }) {
    const navigate = useNavigate();
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allRole, setAllRole] = useState([])
    useEffect(() => {
        API.role.getAll().then(({ data }) => {
            if (data) {
                setAllRole(data.result)
            }
        })
       
    }, [])
   

    

    
    return (


        <Modal {...item} onCancel={() => setModalAdd({})} forceRender onOk={() => form.submit()} okButtonProps={{ loading: item.status, icon: item.icon }}>
            <Form autoComplete="off" form={form} onFinish={formAction} labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}>
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="name" label="Name" required rules={[{required:true, message:"Name is required"}]}  >
                <Input />
            </Form.Item>

            <Form.Item name="email" label="Email" required rules={[{required:true, message:"Email is required"}, {type: 'email'}]}   >
                <Input />
            </Form.Item>

            <Form.Item name="phone_number" label="Phone number" required rules={[{required:true, message:"Phone number is required"}]}  >
                <Input />
            </Form.Item>
            
            <Form.Item name="password" label="Password" required rules={[{required:true, message:"Password is required"}]} >
                <Input.Password />
            </Form.Item>

            <Form.Item
                name="confirmpass"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'));
                    },
                }),
                ]}
            >
            <Input.Password />
            </Form.Item>
            
            <Form.Item name="roleid" label="Role" required rules={[{ required: true, message: 'Role is required' }]}>
                <Select mode="multiple" showSearch filterOption={(input, option) => (option?.search ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={allRole.map(x=>({label: <Flex justify="space-between">{x.role_name} </Flex>, search: x.role_name, value: x.id}))} />
            </Form.Item>
        </Form>

       </Modal>

    )
}


