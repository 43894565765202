import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Form, Input, List, Modal, Space, Tooltip, message, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../configs'
import HighText from '../../components/HL'
// import { useAppStore } from '../../utils/appstate'
import TagModal from './Modal/RoleModal'
const model = 'tag'
export default function TagManagement() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState({})
    const [form] = Form.useForm();
    const [search, setSearch] = useState()
    const [loading, setLoading] = useState(false)
    const ResetData = useCallback(() => {
        setLoading(true)
        API.tags.getAll().then(({ data }) => {
            if (data)
                setData(data.result)
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        ResetData();
    }, [ResetData])

    const AddAction = () => {
        form.resetFields();
        setModal({
            open: true,
            title: <>Add a new {model}</>,
            okText: 'Add',
            type: 'add',
            icon: <PlusOutlined />
        })
    }
    const EditAction = (item) => {
        form.setFieldsValue(item)
        setModal({
            open: true,
            title: <>Edit {model}</>,
            okText: 'Save',
            type: 'edit',
            icon: <SaveOutlined />
        })
    }
    const RemoveAction = ({ industry_id }) => {
        Modal.confirm({
            title: 'You definitely want to delete this ' + model,
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: () => API.tags.remove({ id: industry_id }).then(({ data, error }) => {
                if (data) {
                    notification.success({ message: 'Success' })
                    setModal({});
                    ResetData();
                } else {
                    setModal(m => ({ ...m, status: false }));
                    notification.error({ message: error })
                }
            })
        })
    }
    const FormAction = (formData) => {
        if (data.find(x => x.name.toLowerCase() === formData.name.toLowerCase() && x.industry_id !== formData.industry_id)) {
            message.error('Tag Name already exists');
        } else
            if (modal.type === 'add') {
                setModal(m => ({ ...m, status: true }))
                API.tags.create({ data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' })
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error })
                    }
                })
            } else {
                setModal(m => ({ ...m, status: true }))
                API.tags.update({ id: formData.industry_id, data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' })
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error })
                    }
                })
            }
    }
    return (
        <Flex vertical style={{ maxWidth: 1024, margin: '0 auto' }}>
            <Flex style={{ padding: '0 1rem' }} justify='space-between' wrap='wrap' align='center'>
                <h3 style={{ textTransform: 'capitalize' }}>{model} management</h3>
                <Button type='primary' onClick={AddAction} icon={<PlusOutlined />}>Add a new {model}</Button>
            </Flex>
            <Space wrap style={{ padding: '0 1rem' }}>
                <Input onInput={(e) => setSearch(e.target.value)} value={search} style={{ width: 250 }} prefix={<SearchOutlined />} placeholder='Search tag' />
            </Space>
            <List loading={loading} style={{ flex: 1, overflow: 'auto', padding: 16 }} grid={{ column: 1 }} dataSource={data.filter(x => !search || x.name.includes(search))} 
            renderItem={item => <List.Item>
                <Card hoverable>
                    <Flex justify='space-between' align='center'>
                        <strong><HighText search={search}>{item.name}</HighText></strong>
                        <Space>
                            <Tooltip title={"Edit " + model}>
                                <Button onClick={() => EditAction(item)} icon={<EditOutlined />} />
                            </Tooltip>
                            <Tooltip title={"Remove " + model}>
                                <Button disabled={!item.allowRemove} onClick={() => RemoveAction(item)} danger icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Space>
                    </Flex>
                </Card>
            </List.Item>} />
            <TagModal form={form} modal={[modal, setModal]} formAction={FormAction} />
        </Flex>
    )
}
