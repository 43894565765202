import React, { useRef, useState } from "react";
import { API } from "../configs";
import { Button, Spin, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function UploadImage({ children, setImage, closeAllow, setLoadingBtn }) {
  const input = useRef();
  const [loading, setIsLoading] = useState(false);
  function uploadData(e) {
    input.current.click();
  }
  function uploadDataImage(e) {
    if (e.target.files[0]) {
      var data = new FormData();
      const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp'];
      if (validImageTypes.includes(e.target.files[0].type)) {
        data.append("files", e.target.files[0]);
        setIsLoading(true);
        if(setLoadingBtn) setLoadingBtn(true);
        API.uploadImage({ FormData: data }).then((e) => {
          setImage(e.data.result.path);
          setIsLoading(false);
          if(setLoadingBtn) setLoadingBtn(false);
        });
        if (input.current.value) input.current.value = null

      } else {
        notification.error({ message: "Success" });
      }
    }
  }

  return (
    <div className="imgUpdate" style={{ position: 'relative' }}>
      <div onClick={uploadData}>
        <Spin spinning={loading} size="small">
          {children}
        </Spin>
      </div>
      <input
        onChange={uploadDataImage}
        ref={input}
        style={{ display: "none" }}
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif, image/bmp" //jpg, png, jpeg, gif, bmp
      />
      {closeAllow ? <Button onClick={() => setImage("")} type="text" shape="circle" icon={<CloseOutlined />} className="btn-close" /> : null}
    </div>
  );
}
