import React, { useRef } from 'react'
import { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from './../configs'
import './register.css';
import TextField from '@mui/material/TextField';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

export default function Forgot() {
    const navigate = useNavigate();

    // Password Update Form
    const [formPasswordUpdate, setFormPasswordUpdate] = useState({
        email: "",
    })

    const onChangeForm = (event) => {
        setFormPasswordUpdate({ ...formPasswordUpdate, email: event.target.value });

    };

    const onSubmitHandler = async (event) => {
        debugger
        event.preventDefault();
        // Post to register API

        await axios
            .post("/auth/forgot-password", formPasswordUpdate)
            .then((response) => {
                // move to sign in page
                navigate("/login");
                // add successfully notif
                toast.success(response.data.detail);
            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail);
            });
    };

    return (
        <div className='forgot_container '>
            <div className='forgot_header' style={{ paddingTop: 20, paddingBottom: 5 }}>
                <p style={{ padding: 0, margin: 0 }}>
                    Forgot Password ?
                </p>

                <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20, marginBottom: 10 }} />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="email" className='font_text' style={{ paddingBottom: 10 }}>Your email address</label>
                        {/* <input type="text"
                            id="email"
                            placeholder=" "
                            onChange={onChangeForm}
                        /> */}

                        <TextField id="email" style={{ width: '100%', backgroundColor: 'white', marginTop: 5 }}
                            placeholder="Your email address"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <EmailIcon style={{ color: 'teal' }}></EmailIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={onChangeForm}
                        />

                    </div>
                    <div style={{ paddingBottom: 20, paddingTop: 15 }}>
                        <Button className='button_login' variant="contained" style={{ backgroundColor: 'teal' }}
                            type="submit" >

                            <div role="status">
                                <span>Reset password</span>
                            </div>

                        </Button>
                        <p style={{ textAlign: 'center' }}>
                            Already have login and password?{" "}
                            <Link to="/login">
                                <span>Sign In</span>
                            </Link>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    )
}
