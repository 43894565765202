import { Tabs } from 'antd';
import React, { useState } from 'react'
import Brands from './Brands/Brands';
import BrandEdit from './Brands/BrandEdit';
export default function BrandTab() {
    const [tab, setTab] = useState("new")
    var items = [
        { label: "Edit", key: "edit", children: <BrandEdit tab={tab}/>},
        { label: "New", key: "new", children: <Brands tab={tab} setTab={setTab}/> }, 
      ];
  return <div><Tabs onTabClick={(key)=>setTab(key)} activeKey={tab} type="card" style={{margin: 16}} size="large" items={items} /></div>;
}
