import React from 'react';
import { useAppStore } from "../utils/appstate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Icon from '@ant-design/icons';
import { Avatar, Flex, Space, theme } from 'antd';
import { Card } from 'antd';
const Home = (props) => {

  const appInfo = useAppStore((state) => state.AppInfo);
  const navigate = useNavigate();
  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const [apps, setApps] = React.useState([]);
  const path = useLocation().pathname
  const color = theme.useToken();
  // const auth_token = localStorage.getItem("auth_token");
  // const auth_token_type = localStorage.getItem("auth_token_type");
  // const token = auth_token_type + " " + auth_token;

  // let user = Utils.parseJwt(auth_token);

  React.useEffect(() => {

    let newInfo = { ...appInfo };

    setApps(newInfo.Apps);
  }, [appInfo.Apps])

  const navigateApp = (app) => {
    let newInfo = { ...appInfo };

    let url = app.children.find(x => x.menu_url === path);
    if (!url) {
      url = app.children.find(x => x.menu_url !== "")
      if (url) navigate(url.menu_url);
    }
    if (url) newInfo.currentMenuId = url.id;

    newInfo.Menus = app.children;

    newInfo.IsReady = true;

    updateAppInfo(newInfo);

    if (props.gotoApp) props.gotoApp(app);
  }
  function IconApp({ nameIcon }) {
    var IconRender = Icon[nameIcon];
    return <Avatar style={{ backgroundColor: color.token.colorPrimary }} size={56} icon={IconRender ? <IconRender /> : <Icon.AppstoreOutlined />}></Avatar>

  }
  return (
    <Space wrap>
      {props.home === true ? <Link to="/">
        <Card 
        // onClick={() => { navigateApp(app) }}
        hoverable
        style={{height: 160, width: 150, textAlign: 'center', }}
      >
        <Flex vertical align='center' gap={8}>
          <IconApp nameIcon={'HomeOutlined'}/>
          <strong>Home</strong>
        </Flex>
      </Card></Link> : null}
      {apps.map((app) =>
        <Card key={app.app_id}
          onClick={() => { navigateApp(app) }}
          hoverable
          style={{height: 160, width: 150, textAlign: 'center', }}
        >
          <Flex vertical align='center' gap={8}>
            <IconApp nameIcon={app.icon_app} />
            <strong>{app.app_name}</strong>
          </Flex>
        </Card>
      )}
      
    </Space>
  )

}
export default Home;
