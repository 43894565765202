import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Form, Input, List, Modal, Space, Tag, Tooltip, message, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../configs'
import PermissionModal from './Modal/PermissionModal'
import HighText from '../../components/HL'
import { useAppStore } from '../../utils/appstate'
const model = 'permission'
export default function PermissionManagement() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState({})
    const [form] = Form.useForm();
    const [menu, setMenu] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useAppStore((state) => state.AppInfo.user);
    const ResetData = useCallback(() => {
        setLoading(true)
        API.menu.getAll().then(({ data }) => {
            if (data)
                setMenu(data.result)
        })
            .finally(() => API.permission.getAll().then(({ data }) => {
                if (data)
                    setData(data.result)

            })).finally(() => setLoading(false))

    }, [])

    useEffect(() => {
        ResetData();
    }, [ResetData])
    const AddAction = () => {
        form.resetFields();
        setModal({
            open: true,
            title: <>Add a new {model}</>,
            okText: 'Add',
            type: 'add',
            icon: <PlusOutlined />
        })
    }
    const EditAction = (item) => {
        form.setFieldsValue(item)
        setModal({
            open: true,
            title: <>Edit {model}</>,
            okText: 'Save',
            type: 'edit',
            icon: <SaveOutlined />
        })

    }
    const RemoveAction = ({ id }) => {
        Modal.confirm({
            title: 'You definitely want to delete this ' + model,
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: () => API.permission.remove({ id }).then(({ data, error }) => {
                if (data) {
                    notification.success({ message: 'Success' })
                    setModal({});
                    ResetData();
                } else {
                    setModal(m => ({ ...m, status: false }));
                    notification.error({ message: error })
                }
            })
        })
    }
    const FormAction = (formData) => {
        if (data.find(x => x.name.toLowerCase() === formData.name.toLowerCase() && x.id !== formData.id)) {
            message.error('Permission already exists')
        } else
            if (modal.type === 'add') {
                setModal(m => ({ ...m, status: true }))
                API.permission.create({ data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' })
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error })
                    }
                })
            } else {
                setModal(m => ({ ...m, status: true }))
                API.permission.update({ id: formData.id, data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' });
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error });
                    }
                })
            }
    }
    const TagMenu = ({ id }) => {
        const item = menu.find(x => x.id === id)
        if (item)
            return <Tag>{item.menu_name}</Tag>
        return <Tag>No menu</Tag>
    }

    return (
        <Flex vertical style={{ maxWidth: 1024, margin: '0 auto'}}>
            <Flex style={{ padding: '0 1rem' }} justify='space-between' wrap='wrap' align='center'>
                <h3 style={{ textTransform: 'capitalize' }}>{model} management</h3>
                <Button type='primary' onClick={AddAction} icon={<PlusOutlined />}>Add a new {model}</Button>
            </Flex>
            <Space wrap style={{ padding: '0 1rem' }}>
                <Input onInput={(e) => setSearch(e.target.value)} value={search} style={{ width: 250 }} prefix={<SearchOutlined />} placeholder='Search permission' />
            </Space>
            <List loading={loading} style={{ flex: 1, overflow: 'auto', padding: 16 }} grid={{ column: 1 }} 
            dataSource={data.filter(x => !search || x.name?.toLowerCase().includes(search.toLowerCase()))} renderItem={item =>
                <List.Item>
                    <Card hoverable>
                        <Flex justify='space-between'>
                            <Card.Meta title={<><HighText search={search}>{item.name}</HighText></>} description={<TagMenu id={(item.menu_id)} />}></Card.Meta>
                            <Space>
                                <Tooltip title={"Edit " + model}>
                                    <Button onClick={() => EditAction(item)} icon={<EditOutlined />} />
                                </Tooltip>
                                <Tooltip title={"Remove " + model}>
                                    <Button onClick={() => RemoveAction(item)} danger icon={<DeleteOutlined />} />
                                </Tooltip>
                            </Space>
                        </Flex>
                    </Card>
                </List.Item>} />
            <PermissionModal form={form} modal={[modal, setModal]} formAction={FormAction} />
        </Flex>
    )
}
