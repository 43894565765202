import { Button, Checkbox, Flex, Form, Input, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import UploadImage from '../../components/UploadImage';
import Center from '../../components/Center';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { API, Src } from '../../configs';
export default function SystemInfomation() {
    const [form] = Form.useForm();
    const [item, setItem] = useState({})
    const [noti, local] = notification.useNotification()
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (form) {
            API.systemInfo.get()
                .then(({ data }) => {
                    if (data) {
                        var item = data.result.find(x => x.name === 'image')
                        setItem({ image: item?.value })
                        var object = {
                            name: data.result.find(x => x.name === 'name')?.value,
                            watermark: (data.result.find(x => x.name === 'watermark')?.value === 'checked')
                        }
                        form.setFieldsValue(object)
                    }
                })
        }
    }, [form])
    const formAction = (data) => {
        let lstSystemInfo = []
        lstSystemInfo.push({
            type: 'image',
            name: 'image',
            value: item.image
        })
        lstSystemInfo.push({
            type: 'text',
            name: 'name',
            value: data.name
        })
        lstSystemInfo.push({
            type: 'text',
            name: 'watermark',
            value: data.watermark?'checked':'unchecked'
        })
        setIsLoading(true);
        API.systemInfo.create(lstSystemInfo)
            .then(({ data }) => {
                if (data)
                    noti.success({ message: "Success" })
            }).finally(() => setIsLoading(false))
    }

    return (
        <Flex vertical style={{ maxWidth: 800, margin: '0 auto' }}>
            <Flex style={{ padding: '0 1rem' }} justify='space-between' wrap='wrap' align='center'>
                <h3 style={{ textTransform: 'capitalize' }}>System Infomation</h3>
            </Flex>
            <Form autoComplete="off" form={form} onFinish={formAction} labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}>
                <Form.Item label="Application Logo" >
                    <UploadImage closeAllow
                        setImage={(data) => {
                            setItem({ ...item, image: data });
                        }}
                    >
                        <Center
                            className="effect"
                            direction="column"
                            style={{
                                cursor: "pointer",
                                border: '1px solid #eee'
                            }}
                        >
                            {item.image ? (
                                <img alt="" src={Src(item.image)} style={{ height: '300px', width: '100%', objectFit: 'contain' }} />
                            ) : (
                                <Center width={120} height={300}>
                                    <PlusOutlined />
                                    Upload
                                </Center>
                            )}
                        </Center>
                    </UploadImage>
                </Form.Item>
                <Form.Item name="name" label="Create by Name">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="watermark"
                    valuePropName="checked"
                >
                    <Checkbox>Watermark</Checkbox>
                </Form.Item>
                <Button loading={isLoading} htmlType='submit' size='large' block type='primary' icon={<SaveOutlined />} >Save</Button>
            </Form>
            {local}
        </Flex>
    )
}
