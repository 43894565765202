import { useNavigate, Link } from "react-router-dom"
import { useState } from "react";
import { toast } from "react-toastify";
import axios from '../configs';
import * as React from 'react';
import './register.css';
import '../App.css'
import { Descriptions, Button } from 'antd';
//import Divider from '@mui/material/Divider';
import Border from "../components/Border";

const UserDetail = () => {
  const history = useNavigate();
  const navigate = useNavigate();
  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const query = new URLSearchParams(window.location.search);
  const user_id = query.get('userid');
  const [detailInfoUser, setDetailInfoUser]= React.useState([]);
  //const [value, setValue]= React.useState();
  const [currentRole, setCurrentRole] = React.useState({
    currentRoleId: "",
    //currentRoleName: ""
  })
  //const [checked, setChecked] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [formRegister, setFormRegister] = useState({
      name: "",
      username: "",
      email: "",
      phone_number: "",
      password: "",
      key: "",
      confirmpass:"",
      is_active:1,
      locked:0, 

  })


  let listRole = [];
  const getallRole = async () => {
    axios.get(`/user-managements/get_all_role_user`, {
      headers: { Authorization: token },
    }).then((response) => {
      // console.log("role",response.data.result);
      response.data.result.map((item) => {
        listRole.push({
          id: item.id,
          role_name: item.role_name
        })
      }
      );


      setRoles(listRole);
      toast.success(response.data.result);
    })
      .catch((error) => {
        console.error(error);
      })
  };


  const onSubmitGetInfoUser = async () => {
    
    try {
      axios.get(`/user-managements/get_user_detail_by_id`, {
        headers: { Authorization: token }, params: { userid: user_id }
      }).then(response => {
        console.log("listUser description", response.data.result);
        const result = response.data.result;
       const infoUser = {
      
          key: result.id,
          label:result.username,
          children:result.username
      
      };
      let listUser = [
        {
          key:'1',
          label:"User Name",
          children:result.username
      
        },
        {
          key: '2',
          label:"Email",
          children:result.email
      
      },
      {
        key: '3',
        label:"Name",
        children:result.name
    
    }
      ];
      console.log("listUser description",listUser);
      setDetailInfoUser(listUser);
     

    });
  } catch(error)  {
        console.error(error);
      }
  };


  React.useEffect(() => {
    onSubmitGetInfoUser();
    getallRole();
  }, []);

  

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    await axios
      .post(`/user-managements/update-user`, formRegister, {
        headers: { Authorization: token },
      })
      .then((response) => {

        navigate("/user-management");

        console.log(response)
        toast.success(response.data.detail);

      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);

      });

  }

  function ButtonCancle() {
    return navigate("/opmanual/user-management");

  };


  const handleChange = (event) => {
    console.log("event.target.value", event.target.value);
    const item = {
      currentRoleId: event.target.value

    }
    setCurrentRole(item);
    const role = roles.find(x => x.id === event.target.value);
    formRegister.roleid = event.target.value;
    formRegister.role_name = role.role_name;

  };

  return (
    <>
      
      <Border title="Information User" style={{ flex: 3 }}>
        <Descriptions  items={detailInfoUser} />
      </Border>
      <div style={{float:'right', paddingTop:'20px'}}>
        <Button onClick={ButtonCancle} variant="outlined"> 
          Cancel
        </Button>
      </div>
      {/* </div> */}


    </>
  )

}
export default UserDetail