import { Typography } from 'antd'
import Highlighter from 'react-highlight-words'

export default function HighText({search, deleted, children}) {
  return (
    <Typography.Text type={deleted?'secondary':''} delete={deleted}><Highlighter searchWords={[search]}
    highlightClassName="hightlight"
    textToHighlight={children}
    /></Typography.Text>
  )
}
