import { Badge, Card, Empty, Flex, Modal, Spin } from "antd";
import React from "react";
import Border from "./Border";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../configs";
import { CaretRightOutlined } from "@ant-design/icons";
import HighText from "./HL";
// const Render = ({ dataDefault, search, ref = "" }) => {

//   let min = Math.min(...dataDefault.map(x => x.name.split('.').length))

//   var rs = dataDefault.filter(i => i.name.split('.').length === min)
//     .sort((a,b)=>parseInt(a.name.split('.').at(-1)) > parseInt(b.name.split('.').at(-1)) ? 1: -1)
//     .map(i => ({
//       key: ref +'___'+ i.section_id,
//       title: <Badge status={i.isdeleted?"error":"success"} text={<HighText deleted={i.isdeleted} search={search} children={`section ${i.name}: ${i.title}`}/>}/>,
//       children: Render({dataDefault: dataDefault.filter(ii => ii.name.startsWith(i.name+'.')), search, ref: (ref +'___'+ i.section_id) }),
//       show: false
//     }))
//     .map(i=>
//      <React.Fragment key={i.key} >
//       <Card size="small" hoverable className="outline-item">
//         <Flex>
//           <Flex flex={1} gap={4}>
//             {i.title}
//           </Flex>
//           {console.log(i)}
//           {/* {i.childern.length > 0 ? i.show ? <CaretDownOutlined /> : <CaretRightOutlined /> : null} */}
//         </Flex>
//       </Card>
//       {/* {item.show ?
//         <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'column', gap: 4 }}>
//           <Render onClick={onClick} search={search} dataAll={dataAll} data={item.childern} />
//         </div> : null} */}
//     </React.Fragment>)
//   return rs;
// }
const ShowButton = ({show})=> <div className={(show?'show ': '') +"show-btn"}><CaretRightOutlined /></div>
const renderDataTree = (dataDefault = [], search, ref = '') => {

  let min = Math.min(...dataDefault.map(x => x.name.split('.').length))

  var rs = dataDefault.filter(i => i.name.split('.').length === min)
    .sort((a, b) => parseInt(a.name.split('.').at(-1)) > parseInt(b.name.split('.').at(-1)) ? 1 : -1)
    .map(i => ({
      key: ref + '___' + i.section_id,
      title: <Badge status={i.isdeleted ? "error" : "success"} text={<HighText deleted={i.isdeleted} search={search} children={`section ${i.name}: ${i.title}`} />} />,
      children: renderDataTree(dataDefault.filter(ii => ii.name.startsWith(i.name + '.')), search, ref + '___' + i.section_id)
    }))

  return rs;
}
const Render = ({ data }) => {
  const [show, setShow] = useState([]);
  const toggle = (id) =>{
    let index = show.indexOf(id)
    if(index === -1){
      setShow([...show, id])
    }else{
      setShow(show.filter(x=>x!==id))
    }
  }
  return data.map(i=><React.Fragment key={i.key} >
    <Card onClick={()=>toggle(i.key)} size="small" hoverable className="outline-item">
      <Flex>
        <Flex flex={1} gap={4}>
          {i.title}
        </Flex>
        {i.children.length > 0 ? <ShowButton show={show.includes(i.key)}/>: null}
      </Flex>
    </Card>
    {show.includes(i.key) ?
      <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Render data={i.children} />
      </div> : null}
  </React.Fragment>)
}
export default function ShowOutline({ open, setOpen, Guid, industryId = [], tag = [] }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (open === true) {
      const auth_token = localStorage.getItem("auth_token");
      const auth_token_type = localStorage.getItem("auth_token_type");
      const token = auth_token_type + " " + auth_token;
      axios
        .get("/sections/get-section", {
          headers: { Authorization: token },
          params: { IndustryId: JSON.stringify(industryId) },
        })
        .then((rs) => {
          var dataTmp = renderDataTree(rs.data.result.filter(i=>i.isdeleted !== true))
          // .map((x) => ({
          //   key: x.section_id,
          //   section: x.name,
          //   sectionTitle: x.title
          // }))
          setData(dataTmp);
        });
    }
  }, [industryId, open]);

  return (
    <Modal
      width={800}
      footer={false}
      open={open}
      title={`Outline for ${tag.filter(x => industryId.includes(x.value)).map(x => x.label).join(", ")} Tag`}
      onCancel={() => setOpen(false)}
    >
      <Border>
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {!data ? (
            <div>
              <Spin size="large" />
              Loading...
            </div>
          ) : data.length ? (
            <Render data={data} />
          ) : (
            <Empty />
          )}
        </div>
      </Border>
    </Modal>
  );
}
export { Render }