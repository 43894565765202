import { Form, Input, Modal } from "antd";

export default function TagModal({ modal: [item, setModal], formAction, form }) {
    return <Modal {...item} onCancel={() => setModal({})} forceRender onOk={() => form.submit()} okButtonProps={{loading: item.status, icon: item.icon}}>
        <Form autoComplete="off" defaultValue={{permission_id: []}} form={form} onFinish={formAction} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item name="industry_id" hidden><Input /></Form.Item>
            <Form.Item name="name" label="Tag Name" required rules={[{ required: true, message: 'Tag name is required' }]}>
                <Input />
            </Form.Item>
        </Form>
    </Modal>
}