import { useEffect, useState } from "react";
import { useAppStore } from "../utils/appstate";
import { Avatar, Card, Flex, Space, theme } from "antd";
import { AppstoreOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function AdminPage() {
    const appInfo = useAppStore((state) => state.AppInfo);
    const [MenuSub, setMenuSub] = useState([])
    const color = theme.useToken();
    const navi = useNavigate()
    useEffect(() => {
        if (appInfo) {
            let item = appInfo.Menus.find(x => x.id === appInfo.currentMenuId).children;
            setMenuSub(item??[])
        }
    }, [appInfo])
    return <Flex justify="center" align="start" style={{ padding: 32 }}>
        <Space wrap>
            {MenuSub && MenuSub?.length === 0 ? `You don't have permission on this module,  please contact administrator for more information.` : MenuSub.map((app) =>
                <Card key={app.key}
                    onClick={() => { navi(app.menu_url) }}
                    hoverable
                    style={{ height: 160, width: 150, textAlign: 'center', }}
                >
                    <Flex vertical align='center' gap={8}>
                        <Avatar style={{ backgroundColor: color.token.colorPrimary }} size={56} icon={<AppstoreOutlined />}></Avatar>
                        <strong>{app.menu_name}</strong>
                    </Flex>
                </Card>
            )}
        </Space>
    </Flex>
}
export default AdminPage