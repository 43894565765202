import React from 'react'
import { useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import axios from './../configs'
import './register.css';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LockIcon from '@mui/icons-material/Lock';
import InputAdornment from '@mui/material/InputAdornment';

export default function Reset() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const authenticate = () => {
        var authToken = (window.location.search.match(/token=([^&]+)/) || [])[1]
        if (authToken) {
            localStorage.setItem("auth_token", authToken);
            //localStorage.setItem("auth_token_type", "Bearer");
            //window.location.replace("/")
        }

    }

    React.useEffect(() => {
        authenticate();
    }, [])

    const [formPasswordUpdate, setFormPasswordUpdate] = useState({
        password: "",
        confirmPassword: "",
        passwordsMatch: true,
    })

    const onChangeForm = (event) => {
        setFormPasswordUpdate({ ...formPasswordUpdate, password: event.target.value });
    };

    const handleConfirmPasswordChange = (event) => {
        const confirmPassword = event.target.value;
        setFormPasswordUpdate({
            ...formPasswordUpdate,
            confirmPassword,
            passwordsMatch: formPasswordUpdate.password === confirmPassword
        });
    };

    const onSubmitHandler = async (event) => {
        debugger
        event.preventDefault();
        const auth_token = localStorage.getItem("auth_token");
        const auth_token_type = localStorage.getItem("auth_token_type");
        const token = auth_token_type + " " + auth_token;
        const data = {
            password: formPasswordUpdate.password,
            token: auth_token
        }
        await axios
            // .post("/auth/reset-password", formPasswordUpdate, {
            //     headers: { Authorization: token },
            // })
            .post("/auth/reset-password-by-forget-pass", data)
            .then((response) => {
                navigate("/login");

                toast.success(response.data.detail);

            })
            .catch((error) => {
                toast.error(error.response.data.detail);
            });
    };

    function validateForm() {
        return (
            formPasswordUpdate.password.length > 0 &&
            formPasswordUpdate.confirmPassword.length > 0 &&
            formPasswordUpdate.passwordsMatch == true
        )
    };

    return (
        <div className='Reset_container'>
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <p className='Reset_header' style={{ padding: 0, margin: 0 }}>
                    Reset Password
                </p>
                <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>

                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="password" className='font_text' style={{ marginBottom: 15 }}>New Password</label>
                        <TextField id="password" style={{ width: '100%', backgroundColor: 'white', marginTop: '10px' }}
                            type="password"
                            placeholder=""
                            value={formPasswordUpdate.password}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <LockIcon style={{ color: 'teal' }}></LockIcon>
                                    </InputAdornment>
                                ),
                            }}

                            onChange={onChangeForm}
                        />

                    </div>
                    <div style={{ paddingBottom: 10 }}>
                        <label htmlFor="confirm_password" className='font_text' style={{ marginBottom: 15, fontWeight: 'bold' }}>Confirm Password</label>
                        <TextField id="confirm_password" style={{ width: '100%', backgroundColor: 'white', marginTop: '10px' }}
                            type="password"
                            placeholder=""
                            value={formPasswordUpdate.confirmPassword}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" >
                                        <LockIcon style={{ color: 'teal' }}></LockIcon>
                                    </InputAdornment>
                                ),
                            }}

                            onChange={handleConfirmPasswordChange}
                        />

                    </div>
                    {!formPasswordUpdate.passwordsMatch && <div style={{ color: 'red' }}>Passwords do not match.</div>}

                    <div style={{ paddingBottom: 20, paddingTop: 15 }}>
                        <Button disabled={!validateForm()} className='button_login' variant="contained" style={{ backgroundColor: 'teal' }}
                            type="submit" >
                            <div role="status">
                                <span>Change Password</span>
                            </div>
                        </Button>
                        <p style={{ textAlign: 'center' }}>
                            Already have an account?{" "}
                            <Link to="/login">
                                <span>Sign In</span>
                            </Link>
                        </p>
                    </div>

                </div>

            </form>
        </div>
    )
}
